// DOM Ready
$(function() {

	$('.dropdown-toggle').dropdown();

    //font size
    if($.cookie('fid')==null){
        $('.fs-m a').addClass("current");
    } else {
        $('.fontsize').find($.cookie('fid')).children('a').addClass("current");
    }
    $(".wrapper").css("font-size",$.cookie('fsize'));
    $('.fontsize a').click(function () {
        var idn = $(this).closest('li').attr('class');
        if(idn=="fs-s") {
            var fsize = "90%";
        }else if(idn=="fs-m"){
            var fsize = "100%";
        }else if(idn=="fs-l"){
            var fsize = "115%";
        }
        $(".wrapper").css("font-size",fsize);
        $('.fontsize li a').removeClass("current");
        $(this).addClass("current");
        $.cookie('fsize',fsize,{expires:30,path:'/'});
        $.cookie('fid',"."+$(this).closest('li').attr('class'),{expires:30,path:'/'});
        return false;
    });

});